
      import API from "!./.yarn/__virtual__/style-loader-virtual-720ed1fd89/0/cache/style-loader-npm-3.3.2-3df75edfc7-5ee5ce2dc8.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!./.yarn/__virtual__/style-loader-virtual-720ed1fd89/0/cache/style-loader-npm-3.3.2-3df75edfc7-5ee5ce2dc8.zip/node_modules/style-loader/dist/runtime/singletonStyleDomAPI.js";
      import insertFn from "!./.yarn/__virtual__/style-loader-virtual-720ed1fd89/0/cache/style-loader-npm-3.3.2-3df75edfc7-5ee5ce2dc8.zip/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!./.yarn/__virtual__/style-loader-virtual-720ed1fd89/0/cache/style-loader-npm-3.3.2-3df75edfc7-5ee5ce2dc8.zip/node_modules/style-loader/dist/runtime/setAttributesWithAttributesAndNonce.js";
      import insertStyleElement from "!./.yarn/__virtual__/style-loader-virtual-720ed1fd89/0/cache/style-loader-npm-3.3.2-3df75edfc7-5ee5ce2dc8.zip/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      
      import content, * as namedExport from "!!./.yarn/__virtual__/css-loader-virtual-a10453bd0e/0/cache/css-loader-npm-6.7.3-63aa933400-473cc32b6c.zip/node_modules/css-loader/dist/cjs.js!./.yarn/__virtual__/postcss-loader-virtual-747c0de3e8/0/cache/postcss-loader-npm-7.0.2-c3042b6114-2d251537d4.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./ckeditor.css";
      
      

var options = {"attributes":{"data-cke":true}};

;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!./.yarn/__virtual__/css-loader-virtual-a10453bd0e/0/cache/css-loader-npm-6.7.3-63aa933400-473cc32b6c.zip/node_modules/css-loader/dist/cjs.js!./.yarn/__virtual__/postcss-loader-virtual-747c0de3e8/0/cache/postcss-loader-npm-7.0.2-c3042b6114-2d251537d4.zip/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!./ckeditor.css";
       export default content && content.locals ? content.locals : undefined;
